import { ActivityPage } from "@/src/components/ActivityPage";
import { createBackendApolloClient } from "@/src/lib/Client";
import { addSiteWideProps } from "@/src/lib/sitewideProps";
import {
  CoreActivityPageProps,
  PageExperience,
  getActivityProps,
} from "@/src/page-data/classes";

export default function ActivityDetailPage(props: CoreActivityPageProps) {
  return <ActivityPage {...props} />;
}

export function getServerSideProps({ req, params, locale }) {
  const client = createBackendApolloClient(locale);

  return addSiteWideProps({
    req,
    fn: () =>
      getActivityProps({
        ...params,
        pageExperience: PageExperience.Core,
        client,
      }),
    client,
    locale,
  });
}
